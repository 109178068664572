.footer {
    background-color: #D2A56F;
    color: #FFFFFF;
    padding: 20px 0;
}

.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-content {
    text-align: center;
}

.footer-links {
    margin-bottom: 10px;
}

.footer-links a {
    color: #FFFFFF;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #F0E68C;
    text-decoration: underline;
}

.footer-copyright {
    font-size: 0.9em;
}

@media (max-width: 768px) {
    .footer-links a {
        display: block;
        margin: 10px 0;
    }
}