/* Aboutus.css */

.half-rule {
  margin-left: 0;
  height: 10px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  background-color: #d4a373;
}

.separator {
  height: 10px;
  width: 100%;
  background-color: #d4a373;
  margin: 2rem 0;
}

.fixed-width-card {
  width: 100%;
  margin: 1rem auto;
  border-radius: 20px;
  border: 1px solid #d4a373;
}

.fixed-width-card:not(:last-child) {
  margin-bottom: 2rem;
}

.fixed-width-card .card-img-top {
  width: 100%;
  height: auto;
  display: block;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.custom-card-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1 solid #d4a373;
  border-top: 1px solid #d4a373;
}

.aspect-ratio-16-9 {
  position: relative;
  padding-top: 56.25%;
}

.aspect-ratio-16-9 .card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.custom-card-corner {
  border-radius: 20px;
}

.card-date {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1rem;
  color: #333333;
  font-weight: normal;
  text-align: left; /* Align the date to the left */
}

.card-date-small {
  font-size: 0.8rem;
}

.card-date-large {
  font-size: 1.2rem;
}

:root {
  --card-font-size: 1rem;
}

.custom-card-title {
  font-size: 1rem;
  font-weight: bold;
  text-align: left; /* Align the title to the left */
}

.custom-card-text {
  font-size: 1rem;
  text-align: left; /* Align the text to the left */
}


/* Align text to the left */
.text-left-align {
  text-align: left;
}

/* Align header to the left */
.header-left-align {
  text-align: left;
}


@media (min-width: 768px) {
  .fixed-width-card {
    margin: 2rem auto;
  }
}

@media (min-width: 992px) {
  .fixed-width-card {
    margin: 2rem auto;
  }
}

@media (min-width: 1200px) {
  .fixed-width-card {
    margin: 2.5rem auto;
  }
}