/* CookieConsent.css */

.cookie-banner {
    position: fixed;
    bottom: 0; /* Changed from top to bottom */
    left: 0;
    right: 0;
    background-color: white;
    padding: 20px;
    text-align: center;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Adjusted shadow for consistency */
  }

.cookie-text {
    margin: 0; /* This removes the default margin from the paragraph if desired */
    /* margin-right: 0px;
    flex-grow: 1; */
    /* Ensures the text takes up the available space */
}

.cookie-button {
    margin: 0 5px;
    border-radius: 15px;
    cursor: pointer;
    padding: 1px 10px;
    border: 1px solid #996515;
    background-color: white;
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
}

.cookie-button:hover {
    background-color: #f5f5f5;
    /* Light grey background on hover */
}

.cookie-link {
    text-decoration: underline;
    color: #000;
    /* Optional: set a color for the link */
}

/* Responsive styles */
@media (max-width: 768px) {
    .cookie-banner {
        padding: 5px;
    }

    .cookie-text {
        margin-bottom: 5px;
        /* Adds space between text and buttons on smaller screens */
        text-align: center;
        /* Aligns text to the left on smaller screens */
    }

    .cookie-button {
        margin: 3px;
        /* Smaller margin for buttons */
        padding: 1px 5px;
        /* Smaller padding for buttons */
        font-size: 0.6rem;
        /* Smaller font size for buttons */
    }
}