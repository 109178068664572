/* CarouselComponent.css */
.carousel-item {
  height: 100vh;
  /* Set the height of the carousel */
  background-size: cover;
  background-position: center;
}

.carousel-caption {
  background: rgba(255, 255, 255, 0.15);
  /* Semi-transparent white background for the caption */
  padding: 2rem;
  border-radius: 20px;
  width: 80%;
  /* Caption occupies 80% of the width */
  /* position: absolute; */
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  /* White font color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* Text shadow for better legibility */
  /* #d4a373 */
}

.carousel-caption h3 {
  font-weight: bold;
  /* Make title text bold */
  font-size: 1.5rem;
  /* Larger font size for the title; adjust as needed */
}

.carousel-caption p {
  font-size: 1.0rem;
  /* Larger font size for the paragraph; adjust as needed */
}


.carousel-indicators .active {
  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top-width: 0px;
  border-top-style: none;
  border-top-color: currentcolor;
  border-bottom-width: 0px;
  border-bottom-style: none;
  border-bottom-color: currentcolor;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  /* opacity: .5; */
  transition: opacity .6s ease;
  transition-property: opacity;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-radius: 50%;
}