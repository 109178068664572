:root {
    --gba-opacity: 0.7; /* Default opacity for all gba classes */
  }

  [class^="gba"] {
    opacity: var(--gba-opacity); /* Apply the custom property */
  }

.gba0 { fill: url(#gradient0); }
.gba1 { fill: url(#gradient1); }
.gba2 { fill: url(#gradient2); }
.gba3 { fill: url(#gradient3); }
.gba4 { fill: url(#gradient4); }
.gba5 { fill: url(#gradient5); }
.gba6 { fill: url(#gradient6); }
.gba7 { fill: url(#gradient7); }
.gba8 { fill: url(#gradient8); }
.gba9 { fill: url(#gradient9); }
.gba10 { fill: url(#gradient10); }
.gba11 { fill: url(#gradient11); }
.gba12 { fill: url(#gradient12); }

.gba0 {
    /* fill: #FDCF49; */
    fill: #508FFF;
    /* fill: radial-gradient(circle at center, #508FFF 0%, #0000FF 100%); */
    
}

.gba1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #EF987E;
    /* fill: radial-gradient(circle at center, #EF987E 0%, #FF0000 100%); */
}

.gba2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FC7E37;
}

.gba3 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #50CEFF;
}

.gba4 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #71BE75;
}

.gba5 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #4C8FDF;
}

.gba6 {
    stroke: #ffffff; /* Sets the color of the stroke (border) to black */
    stroke-width: 0.1px; /* Sets the width of the stroke to 2 pixels */
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #6d42b4;
}

.gba7 {
    fill: #FF9B2E;
}

.gba8 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #DE8DC2;
}

.gba9 {
    fill: #CF7F38;
}

.gba10 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #B4CE5E;
}

.gba11 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #636F8C;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 2, 4;
}

.gba12 {
    fill: #2A2A2A;
}

.gba13 {
    font-family: "open_sansregular", Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
}

.gba14 {
    font-size: 1.125rem;
}

.gba15 {
    letter-spacing: 1px;
}

.gba16 {
    fill: #5FB9E4;
}

.d_map {
    max-width: 100%; /* Ensures the SVG is responsive and doesn't overflow */
    max-height: 100vh; /* Optional: Ensures the SVG height doesn't exceed the viewport height */
    margin: auto; /* Centers the SVG in the container */
    /* background-image: radial-gradient(circle at center, rgba(255, 255, 204, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%); */
    fill: transparent; /* Set the fill to transparent to reveal the gradient background on hover */
  }
  

.d_map path {
    transition: fill 0.2s ease-in-out;
}

.d_map path:hover {
    fill: rgba(255,255,204,0.8); /* Lighten the fill color on hover for interactive 3D effect */
}


use {
    stroke: #FC7E37 !important;
    stroke-width: 3 !important;
    cursor: pointer;
}

.d_map #photo-over {
    display: none;
}

.centered-text {
    text-anchor: middle; /* Centers the text horizontally */
    alignment-baseline: middle; /* Centers the text vertically */
    /* font-size: 24px;  */
    /* Example font size */
    fill: #000000; /* Example fill color, black */
    /* Ensure any other properties are not causing the text to be invisible */
  }
