/* Tools.css */

.separator {
  height: 10px;
  width: 100%;
  background-color: #ffffff;
  margin: 2rem 0;
}

.fixed-width-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
}

.fixed-width-card:hover {
  transform: scale(1.05); /* Slightly increase the size of the card */
  box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* Adds shadow to lift the card off the page */
}

.card-img-container {
  flex: 0 0 80px; /* Adjust width as necessary */
  width: 80px; /* Equal width */
  height: 80px; /* Equal height for 1:1 aspect ratio */
  overflow: hidden; /* Keeps the image within the boundaries */
  border-radius: 10px; /* Rounded corners for the square */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-img-left {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area without stretching */
  border-radius: 10px; /* Rounded corners for the image */
}
.custom-card-title, .custom-card-text {
  padding: 0 15px; /* Adds padding around text for spacing */
}

.custom-card-divider {
  margin: 0.5rem 0;
}

.card-date {
  font-size: 0.8rem;
  font-style: italic;
  color: #333333;
  text-align: right;
  margin-bottom: 1rem;
}

.card-topic {
  font-size: 0.8em;
  font-weight: bold;
  color: #d4a373;
  margin-bottom: 0.5em;
}

.card-footer {
  background-color: transparent;
  padding-left: 0;
}

.form-select-sm.custom-select {
  position: relative;
  border-color: #d4a373;
  background-color: #fff;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select-sm.custom-select::after {
  content: "\25BC";
  color: #d4a373;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-select-sm.custom-select:focus {
  border-color: #c49a6c;
  box-shadow: 0 0 0 0.2rem rgba(212, 163, 115, 0.25);
}

.card-hover {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.card-hover:hover {
  transform: translateY(-10px); /* Moves the card up when hovered */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Optional: Adds shadow for depth */
}



@media (max-width: 768px) {
  .fixed-width-card {
    flex-direction: column;
    display: flex;
  }

  .card-img-container {
    width: 100%; /* Full width on smaller screens */
    height: 100%; /* Set height relative to parent */
    border-radius: 10px; /* Maintain rounded corners */
  }

  .card-img-left {
    width: 100%; /* Full width */
    height: 100%; /* Full height to fill its container */
  }
}