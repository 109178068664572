/* header.css */

/* Define CSS variables for easy management of font and size */
:root {
  --header-font-family: 'Arial', sans-serif;
  /* Default font, you can change it later */
  --header-font-size: 1.2rem;
  /* Default size, you can change it later */
  --nav-link-font-size: 1rem;
  /* Slightly smaller than the brand for better hierarchy */
}

.navbar {
  /* background-color: transparent !important; */
  background-color: rgba(153,101,21, 0.1) !important; 
  /* background-color: rgba(135, 206, 235, 0.5) !important;  */

  /* Override with sky blue */
  
  /* Set background to transparent */
  /* Other styles for the navbar */
  
}


/* Custom styles for the navbar brand (logo/name) */
.navbar-brand {
  font-family: var(--header-font-family);
  font-size: 1rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 1) !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 20px;
  padding: 0rem 1rem; /* Add padding to the right */
  display: flex;
  justify-content: flex-start; /* Align to the left */
  align-items: center;
  height: 40px;
  margin-right: 20px; /* Add margin to the right */
  
}


/* Custom styles for the navbar links */
.nav-link {
  font-family: var(--header-font-family);
  font-size: var(--nav-link-font-size);
  font-weight: bold;
  /* Make the font bold */
  /* Adjust the color for visibility against the light background */
  color: #ffffff !important;
  /* Ensure the color is white */
  /* color: #333 !important;  */
  /* Dark color for better contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* Text shadow for readability */
}


/* Target each span (letter) and set a different color */
.navbar-brand span:nth-child(1) { color: #e6194b; }
.navbar-brand span:nth-child(2) { color: #3cb44b; }
.navbar-brand span:nth-child(3) { color: #ffe119; }
.navbar-brand span:nth-child(4) { color: #4363d8; }
.navbar-brand span:nth-child(5) { color: #f58231; }
.navbar-brand span:nth-child(6) { color: #911eb4; }
.navbar-brand span:nth-child(7) { color: #46f0f0; }
.navbar-brand span:nth-child(8) { color: #f032e6; }
.navbar-brand span:nth-child(9) { color: #bcf60c; }
.navbar-brand span:nth-child(10) { color: #fabebe; }
.navbar-brand span:nth-child(11) { color: #008080; }
.navbar-brand span:nth-child(12) { color: #e6beff; }
.navbar-brand span:nth-child(13) { color: #9a6324; }
.navbar-brand span:nth-child(14) { color: #fffac8; }
.navbar-brand span:nth-child(15) { color: #800000; }

.navbar-light .navbar-toggler:focus {
  outline: none;
  outline-color: rgba(255, 255, 255,0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Ensure the nav links are on the right-hand side for larger screens */
.navbar-collapse {
  justify-content: flex-end;
  
}

/* Custom styles for the toggle button (hamburger menu) */
.navbar-toggler {
  border-color: rgb(255, 255, 255);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */

  /* background-color: #343a40;  */
  /* Example: a dark background color */
  /* Make the border semi-transparent */
}

.navbar-toggler-icon {
  background-image: none !important;
  background-color: white;
  height: 2px;
  width: 25px;
  margin: 10px 0;
  position: relative;
  transition: all 0.35s ease-out;
  transform-origin: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */
}

.navbar-toggler-icon::before {
  display: block;
  background-color: white;
  height: 2px;
  content: "";
  position: relative;
  top: -6px;
  transition: all 0.15s ease-out; /* Taken down to hide quicker */
  transform-origin: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */
}

.navbar-toggler-icon::after {
  display: block;
  background-color: white;
  height: 2px;
  content: "";
  position: relative;
  top: 4px;
  transition: all 0.35s ease-out;
  transform-origin: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */
}

.navbar-dark .navbar-toggler-icon,
.navbar-dark .navbar-toggler-icon::before,
.navbar-dark .navbar-toggler-icon::after {
  background-color: var(--bs-gray-100);
}

/* .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  transform: rotate(45deg);
} */

/* .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
  opacity: 0;
} */

/* .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
  transform: rotate(-90deg) translateX(7px);
} */

/* .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
} */
/* 
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

} */

/* If the toggler has a custom class, replace '.navbar-light .navbar-toggler' with your custom class selector */

/* Make the navbar links full width on smaller screens */

.custom-nav-link {
  font-size: 1.5rem; /* Increase font size to 1.25rem */
}

@media (max-width: 991px) {
  .nav-link {
    width: 100%;
    text-align: right;

    /* Align to the right if that's your preference */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Text shadow for readability */

  }

  .navbar-brand {
    font-family: var(--header-font-family);
    font-size: 1.5rem; /* Increased font size */
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7) !important;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 0, 0, 0.0); /* Set background color to sky blue */
    border-radius: 20px; /* Set border radius to 20px for rounded corners */
    padding: 0.5rem; /* Add some padding inside the brand element */
    display: flex; /* Use flexbox to center the content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 40px; /* Set a specific height for the .navbar-brand */
    /* Adjust height as needed, or remove if you want the height to be determined by content + padding */
  }

}