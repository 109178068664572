/* News.css */

/* Add the following styles for the card-meta container */
.card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card-photo-credit {
  font-size: 0.8rem;
  color: #999999;
  text-align: left;
}

.card-date {
  font-size: 1rem;
  font-style: italic;
  color: #333333;
  font-weight: normal;
  text-align: right;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  opacity: 0.9;
  transition: opacity 0.3s, background-color 0.3s, border-color 0.3s;
  background-color: rgba(212, 163, 115, 0.7);
  border: 2px solid #ffffff; 
  outline: none; /* Ensure no focus outline on click */
}

.back-to-top:hover {
  opacity: 1;
  background-color: rgba(212, 163, 115, 0.9); /* Hover color changed to gold */
  border: rgba(255, 255, 255, 0.6);
   /* solid #8B4513;  Changed border to brown  color: white; */
  outline: none; /* Ensure no focus outline on click */
}

.back-to-top:focus {
  outline: none; /* Remove default blue outline */
  border: rgba(255, 255, 255, 0.6);
   /* solid #8B4513;  Changed border to brown  color: white; */
  box-shadow: 0 0 5px rgba(212, 163, 115, 0.8); /* Add a matching box shadow on focus */
}

.back-to-top:active {
  background-color: rgba(212, 163, 115, 0.8); /* Click color changed to a slightly darker golden */
  border: rgba(255, 255, 255, 0.6);
   /* solid #8B4513;  Changed border to brown  color: white; */
  outline: none; /* Ensure no focus outline on click */
}

/* Responsive styling */
@media (max-width: 767px) {
  .fixed-width-card {
    width: 100%;
    margin: 1rem auto;
    border-radius: 15px;
  }

  .fixed-width-card .card-img-top {
    border-radius: 15px 15px 0 0;
  }

  .custom-card-title {
    font-size: 0.8rem;
  }

  .custom-card-text {
    font-size: 0.7rem;
  }

  .card-date {
    font-size: 0.7rem;
  }

  .card-photo-credit {
    font-size: 0.7rem;
  }

  .card-topic {
    font-size: 0.7em;
  }
}

/* Existing styles */
.half-rule {
  margin-left: 0;
  height: 10px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  background-color: rgba(212, 163, 115, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.separator {
  height: 10px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  margin: 2rem 0;
}

.fixed-width-card {
  width: 100%;
  margin: 1rem auto;
  border-radius: 20px;
  border: 1px solid #D2691E; /* Changed to golden brown */
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.fixed-width-card:not(:last-child) {
  margin-bottom: 2rem;
}

.fixed-width-card .card-img-top {
  width: 100%;
  height: auto;
  display: block;
}

.custom-card-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1;
  border-top: 1px solid rgba(212, 163, 115, 0.5);
}

.aspect-ratio-16-9 {
  position: relative;
  padding-top: 56.25%;
}

.aspect-ratio-16-9 .card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.custom-card-corner {
  border-radius: 20px;
}

.date-photo-credit-row {
  display: flex;
  margin-bottom: 1rem;
}

.card-image {
  font-size: 0.8rem;
  font-style: italic;
  text-align: right;
}

.card-date {
  font-size: 0.8rem;
  text-align: left;
}

.card-topic {
  font-size: 0.8em;
  font-weight: bold;
  color: rgba(212, 163, 115, 0.8);
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

.card-footer {
  background-color: transparent;
  padding-left: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.news-container {
  padding: 0 20px;
}

:root {
  --card-font-size: 1rem;
}

.custom-card-title {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
}

.custom-card-text {
  font-size: 0.8rem;
  text-align: left;
}

.custom-card-text-left {
  font-size: 0.7rem;
  text-align: left;
}

.custom-card-text-right {
  font-size: 0.7rem;
  text-align: right;
  font-style: italic;
}

.form-select-sm.custom-select {
  position: relative;
  border-color: rgba(212, 163, 115, 0.8);
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.form-select-sm.custom-select::after {
  content: "\25BC";
  color: rgba(212, 163, 115, 0.8);
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-select-sm.custom-select:focus {
  border-color: rgba(196, 154, 108, 0.8);
  box-shadow: 0 0 0 0.2rem rgba(212, 163, 115, 0.5);
}

.my-pagination .pagination .pagination-index {
  color: rgba(212, 163, 115, 0.8);
  border: 1px solid rgba(212, 163, 115, 0.5);
  border-radius: 4px;
}

.my-pagination .page-item .page-link {
  color: rgba(212, 163, 115, 0.8);
  border: 1px solid rgba(212, 163, 115, 0.5);
  border-radius: 4px;
}

.my-pagination .pagination .pagination-index.active {
  background-color: rgba(212, 163, 115, 0.8) !important;
  color: #fff !important;
}

.my-pagination .page-item.active .page-link {
  background-color: rgba(212, 163, 115, 0.8) !important;
  color: #fff !important;
}

.edit-button {
  background-color: rgba(255, 193, 7, 0.8);
  color: #fff;
  border: none;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.edit-button:hover,
.edit-button:focus {
  background-color: rgba(224, 168, 0, 0.8);
  color: #fff;
}

.delete-button {
  background-color: rgba(220, 53, 69, 0.8);
  color: #fff;
  border: none;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.delete-button:hover,
.delete-button:focus {
  background-color: rgba(200, 35, 51, 0.8);
  color: #fff;
}

.button-gap {
  margin-bottom: 0.5rem;
}

.card-hover {
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 30px;
}

.card-hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 5px 10px rgba(212, 163, 115, 0.8);
}

@media (min-width: 768px) {
  .fixed-width-card {
    margin: 2rem auto;
  }
}

@media (min-width: 992px) {
  .fixed-width-card {
    margin: 2rem auto;
  }
}

@media (min-width: 1200px) {
  .fixed-width-card {
    margin: 2.5rem auto;
  }
}