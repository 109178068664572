/* AI4Good.css */
.half-rule {
  margin-left: 0;
  height: 10px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  background-color: #e5e5e5;
}

.separator {
  height: 10px;
  /* Height of the separator line */
  width: 100%;
  /* Full container width */
  background-color: #ffffff;
  /* Light grey color, you can change it as needed */
  margin: 2rem 0;
  /* Spacing above and below the separator */
}

.card-text {
  text-align: left;
}

.fixed-width-card {
  width: 100%;
  margin: 1rem auto; /* Adjusted to add space at the top and bottom */
  border-radius: 20px;
}

/* Add bottom margin to all cards except the last child to maintain consistent spacing */
.fixed-width-card:not(:last-child) {
  margin-bottom: 2rem; /* Adjust the value to your preferred space between cards */
}

.fixed-width-card .card-img-top {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 20px; /* Replace with the radius you want */

}

.aspect-ratio-16-9 {
  position: relative;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.aspect-ratio-16-9 .card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* custom.css file */
.custom-card-corner {
  border-radius: 20px;
  /* Replace with the radius you want */
}


.custom-card-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid #b1adad; /* Adjust color to match your design */
}


/* Pagination Index Color, Border, and Rounded Corners with increased specificity */
.my-pagination .pagination .pagination-index {
  color: #d4a373; /* Golden brown color for the text */
  border: 1px solid #d4a373; /* Golden brown border color */
  border-radius: 4px; /* Rounded corners */
  
}

.my-pagination .page-item .page-link {
  color: #d4a373; /* Golden brown color for the text */
  border: 1px solid #d4a373; /* Golden brown border color */
  border-radius: 4px; /* Rounded corners */
}


/* Active Pagination Index with Golden Brown Background and !important to override */
.my-pagination .pagination .pagination-index.active {
  background-color: #d4a373 !important; /* Golden brown background color for the active index */
  color: #fff !important; /* White color for the text to contrast the background */
}

.my-pagination .page-item.active .page-link {
  background-color: #d4a373 !important; /* Golden brown background color for the active index */
  color: #fff !important; /* White color for the text to contrast the background */
}





/* Adjust the top margin for medium devices and up */
@media (min-width: 768px) {
  .fixed-width-card {
    margin: 2rem auto; /* Increased top and bottom margin */
  }
}

/* Adjust the top margin for large devices and up */
@media (min-width: 992px) {
  .fixed-width-card {
    margin: 2rem auto; /* Consistent with medium devices */
  }
}

/* For extra large screens, you might want to have even more space */
@media (min-width: 1200px) {
  .fixed-width-card {
    margin: 2.5rem auto; /* Increased top and bottom margin for extra large screens */
  }
}